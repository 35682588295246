import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import GoToTop from "./components/common/GoToTop";
const CategoryData = React.lazy(() =>
  import("./components/common/CategoryData")
);
const SingleNews = React.lazy(() => import("./components/common/SingleNews"));
const Health = React.lazy(() => import("./pages/Health"));
const Education = React.lazy(() => import("./pages/Education"));
const Bfsi = React.lazy(() => import("./pages/Bfsi"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const TermsConditions = React.lazy(() => import("./pages/TermsConditions"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Advertise = React.lazy(() => import("./pages/Advertise"));
const SearchNews = React.lazy(() => import("./components/common/SearchNews"));
const Apac = React.lazy(() => import("./pages/Apac"));
const EMEA = React.lazy(() => import("./pages/EMEA"));
const USA = React.lazy(() => import("./pages/USA"));
const India= React.lazy(() => import("./pages/India"));
const Shorts = React.lazy(() => import("./pages/Shorts"));
const FourRegionsNews = React.lazy(() => import("./pages/FourRegionsNews"));
const Error = React.lazy(() => import("./pages/Error"));

function App() {
  const loadingDiv = (
    <div className="grid min-h-[calc(100vh-3.5rem)] place-items-center">
      <div className="spinner"></div>
    </div>
  );
  return (
    <div className="flex min-h-screen w-screen flex-col font-roboto">
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/apac"
          element={
            <Suspense fallback={loadingDiv}>
              <Apac />
            </Suspense>
          }
        />

        <Route
          path="/emea"
          element={
            <Suspense fallback={loadingDiv}>
              <EMEA />
            </Suspense>
          }
        />

        <Route
          path="/India"
          element={
            <Suspense fallback={loadingDiv}>
              <India/>
            </Suspense>
          }
        />
        <Route
          path="/usa"
          element={
            <Suspense fallback={loadingDiv}>
              <USA />
            </Suspense>
          }
        />
        <Route
          path="/all-News"
          element={
            <Suspense fallback={loadingDiv}>
              <FourRegionsNews/>
            </Suspense>
          }
        />

        <Route
          path="/all-shorts"
          element={
            <Suspense fallback={loadingDiv}>
              <Shorts />
            </Suspense>
          }
        />

        <Route
          path="/:categoryName"
          element={
            <Suspense fallback={loadingDiv}>
              <CategoryData />
            </Suspense>
          }
        />
        <Route
          path="/:categoryName/:title"
          element={
            <Suspense fallback={loadingDiv}>
              <SingleNews />
            </Suspense>
          }
        />
        <Route
          path="/Health-Technology"
          element={
            <Suspense fallback={loadingDiv}>
              <Health />
            </Suspense>
          }
        />
        <Route
          path="/health/:categoryName/:title"
          element={
            <Suspense fallback={loadingDiv}>
              <SingleNews />
            </Suspense>
          }
        />
        <Route
          path="Education-Technology"
          element={
            <Suspense fallback={loadingDiv}>
              <Education />
            </Suspense>
          }
        />
        <Route
          path="/education/:categoryName/:title"
          element={
            <Suspense fallback={loadingDiv}>
              <SingleNews />
            </Suspense>
          }
        />
        <Route
          path="/BFSI"
          element={
            <Suspense fallback={loadingDiv}>
              <Bfsi />
            </Suspense>
          }
        />
        <Route
          path="/bfsi/:categoryName/:title"
          element={
            <Suspense fallback={loadingDiv}>
              <SingleNews />
            </Suspense>
          }
        />
        <Route
          path="/about-us"
          element={
            <Suspense fallback={loadingDiv}>
              <AboutUs />
            </Suspense>
          }
        />
        <Route
          path="/T-&-C"
          element={
            <Suspense fallback={loadingDiv}>
              <TermsConditions />
            </Suspense>
          }
        />
        <Route
          path="/Privacy-Policy"
          element={
            <Suspense fallback={loadingDiv}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/advertising"
          element={
            <Suspense fallback={loadingDiv}>
              <Advertise />
            </Suspense>
          }
        />
        <Route
          path="/news-search"
          element={
            <Suspense fallback={loadingDiv}>
              <SearchNews />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={loadingDiv}>
              <Error />
            </Suspense>
          }
        />
      </Routes>
      <GoToTop />
    </div>
  );
}

export default App;
