import { configureStore } from "@reduxjs/toolkit";
import newsReducer from "../redux/slices/newsSlice";
import categoryAndsubcategoryNewsReducer from "../redux/slices/categoryAndsubcategory";
import singleNewsReducer from "../redux/slices/singleNewsSlice";
import latestNewsReducer from "../redux/slices/latestNews";
import customAdsReducer from "../redux/slices/customAdsSlice";
import categoriesReducer from "../redux/slices/categoriesSlice";
import navbarsReducer from "../redux/slices/navbarSlice";
import specificCategoryNewsReducer from "../redux/slices/specificCategorySlice"
import searchReducer from "../redux/slices/searchSlice"
import youtubeReducer from './slices/youtubeSlice';
import regionNewsSliceReducer from "./slices/regionNewsSlice"
import sectionSliceReducer from "./slices/sectionSlice"
export const store = configureStore({
  reducer: {
    news: newsReducer,
    categoryAndsubcategoryNews: categoryAndsubcategoryNewsReducer,
    singleNews: singleNewsReducer,
    latestNews: latestNewsReducer,
    customAds: customAdsReducer,
    categories: categoriesReducer,
    navbars: navbarsReducer,
    specificCategoryNews:specificCategoryNewsReducer,
    search: searchReducer,
    youtube: youtubeReducer,
    regionNews:regionNewsSliceReducer,
    SectionNews: sectionSliceReducer, 
  },
});
